<template>
    <v-card class="ma-3" style="text-align: center;">
        <page-progress :is-loading="isLoading"/>
        <page-title :title="pageTitle" :icon="pageIcon"/>
        <v-container fluid>
            <slot></slot>
        </v-container>
    </v-card>
</template>
<script>
    import PageTitle from "./PageTitle";
    import PageProgress from "./PageProgress";

    export default {
        name: "Card",
        components: {PageTitle, PageProgress},
        props: {
            isLoading: {
                type: Boolean,
                default: function () {
                    return false;
                }
            },
            pageTitle: {
                type: String,
                default: function () {
                    return "";
                }
            },
            pageIcon: {
                type: String,
                default: function () {
                    return "";
                }
            }
        }
    };
</script>
